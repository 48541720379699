<template>
	<div class="wrapper">
		<div class="table">
			<vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
			</vxe-grid>
			<vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize"
				:page-sizes="tablePage.pageSizes" :total="tablePage.totalResult" :layouts="layouts"
				@page-change="pageChange"></vxe-pager>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapActions,
	mapMutations
} from "vuex";
import api from '@/store/API/api'
export default {
	props: {
		//是否分租户
		hasTenant: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			loading: false,
			seachStatus: false,
			searchData: {},
			// 分页的参数配置
			tablePage: {
				totalResult: 0,
				currentPage: 1,
				pageSize: 10,
				align: "left",
				pageSizes: [10, 20, 50, 100, 500, 1000],
				perfect: true
			},
			tableColumn: [{
				type: 'seq',
				title: '序号',
				width: 50,
				align: "center"
			},
			{
				field: 'name',
				title: '姓名',
				showOverflow: true,
				align: "center",
				minWidth: "120"
			},
			{
				field: 'sex',
				title: '性别',
				showOverflow: true,
				align: "center",
				minWidth: "60"
			},
			{
				field: 'age',
				title: '年龄',
				showOverflow: true,
				align: "center",
				minWidth: "60"
			},
			{
				field: 'idNum',
				title: '身份证号',
				showOverflow: true,
				align: "center",
				minWidth: "120"
			},
			{
				field: 'serviceCount',
				title: '服务次数',
				showOverflow: true,
				align: "center",
				minWidth: "60"
			},
			{
				field: 'contract',
				title: '联系方式',
				showOverflow: true,
				align: "center",
				minWidth: "120"
			},
			{
				field: 'address',
				title: '地址',
				showOverflow: true,
				align: "center",
				minWidth: "120"
			}
			],
			tableData: [],
		}
	},
	computed: {
		...mapState([
			"layouts"
		]),
		...mapGetters(['seqId'])
	},
	watch: {
		seqId() {
			// this.getLists()
		}
	},
	created() {
		this.initView()
	},
	methods: {
		...mapActions([
			"getHomePersonListServe"
		]),
		initView() {
			this.getLists();
		},
		// 是否显示查询功能
		showSearch(bool) {
			this.seachStatus = bool;
		},
		goPage(type, itemData) {
			this.$router.push({
				name: 'AddPractitioners',
				params: {
					type: type,
					id: type == 'add' ? 'add' : itemData.id
				},
				query: {
					item: type == 'add' ? 'add' : JSON.stringify(itemData)
				}
			})
		},
		getLists() {
			this.loading = true;
			this.getHomePersonListServe({
				current: this.tablePage.currentPage,
				size: this.tablePage.pageSize,
				param: {
					workPersonId: this.$route.params.id,
					hasTenant: this.hasTenant
				}
			}).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.records;
					this.tablePage.totalResult = +res.data.total;
				}
				this.loading = false;
			})
		},
		// 分页功能
		pageChange(item) {
			if (item.type == "size") {
				this.tablePage.currentPage = 1;
			} else {
				this.tablePage.currentPage = item.currentPage;
			}
			this.getLists();
		}
	}
}
</script>

<style lang="scss" scoped>
.table {
	overflow-y: auto;
}

.wrapper {
	.datetime {
		display: flex;
		align-items: center;

		span {
			margin: 0 3px;
		}
	}
}

.sign i {
	margin-right: 3px;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
</style>
