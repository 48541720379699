<template>
  <!-- 开始刷卡，结束刷卡 -->
  <div >
    <el-dialog  :title="title" :visible.sync="dialogTableVisible" @close="closeDialog" width="50%" >
      <el-form :model="form" label-width="auto" class="demo-ruleForm" :label-position="labelPosition" size="small" ref="form"  :rules="rules">
      <el-form-item label="卡号："  prop="oldPersonCardNum" >
        <el-input v-model="form.oldPersonCardNum" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="体温：" v-if="title=='开始刷卡'"  prop="bodyTemperature">
        <el-input v-model="form.bodyTemperature" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="personSignType==1?'服务项目':'职业'" v-if="title=='开始刷卡'" prop="signActionPidValSet">
          <el-checkbox-group v-model="form.signActionPidValSet" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in option" :label="item.dictPidVal" :key="item.dictPidVal">{{item.dictDesc}}</el-checkbox>
          </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commitBtn('form')" size="medium">确 定</el-button>
      <el-button @click="closeDialog('form')" size="medium">取 消</el-button>
    </div>
</el-dialog>
  </div>
</template>

<script>
import {mapActions } from 'vuex'

export default {
  name:'SwipingCard',
  props: {
        signTitle:'',
        personSignType:Number
    },
  data(){
    return {
      dialogTableVisible :true,
      // title:'开始刷卡',
      labelPosition:'right',
      form:{
        oldPersonCardNum:'',
        bodyTemperature:'',
        scene:JSON.parse(this.$route.query.item).orgType,
        signActionDicTypeCode:'sign_active_type',
        signType:'IN',
        signActionPidValSet:[],
        sceneId:this.$route.params.id
      },
      title :this.signTitle,
      rules: {
        oldPersonCardNum:[ { required: true, message: '请填写卡号', trigger: 'blur' }],
        bodyTemperature:[ { required: true, message: '请填写体温', trigger: 'blur' }],
        servicePro:[ { required: true, message: '请选择服务', trigger: 'blur' }]

      },
      signActiveType:[],
      option:[]
    }
  },
  created(){
    if(this.title=='开始刷卡'){
      this.form.bodyTemperature = (Math.random() * (36.8 - 35.8) + 35.8).toFixed(1)
    }
    // console.log((Math.random() * (37.3 - 35.8) + 35.8).toFixed(1));
    this.getDicList()

  },
  methods:{
    ...mapActions([
      'getSysDictServe',
      'getOldPsersonSign',
      'getworkPersonSign'
    ]),
        // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this.option = res.data
          if(this.personSignType==2){
            this.option.map(item=>{
              console.log(item);
              if(item.dictDesc=='养老顾问'){
                console.log('shio');
                this.form.signActionPidValSet .push( item.dictPidVal)
              }
            })
            // console.log( this.form.signActionPidValSet);
          }
        }
      })
    },
    //获取服务项目
    getDicList() {
      if(this.personSignType==1){
        this.getSysDictList('sign_active_type', 'option') //服务项目
      }else{
          //服务项目
      }

    },

    closeDialog(){
      this.$parent.isSwipingCard = false
    },
    commitBtn(formName){
      // console.log(formName);
        this.$refs[formName].validate((valid) => {
          if (valid) {

            if(this.title=='结束刷卡'){
              this.form.signType = 'OUT'
            }
            if(this.personSignType==1){
              let param = {
                oldPersonCardNum:this.form.oldPersonCardNum,
                bodyTemperature:this.form.bodyTemperature,
                scene:JSON.parse(this.$route.query.item).orgType,
                signActionDicTypeCode:'sign_active_type',
                signType:this.form.signType,
                signActionPidValSet:this.form.signActionPidValSet,
                sceneId:this.$route.params.id
              }
              this.getOldPsersonSign(param).then((res) => {
                if (res.code == 200) {
                  this.$message.success('刷卡成功')
                  this.$parent.getList()
                  this.$parent.isSwipingCard = false
                }else if(res.code != 200 ||res.code != '200'){
                  this.$message.error(res.msg);

                }
              })
            }else if(this.personSignType==2){
              console.log(JSON.parse(this.$route.query.item).orgType);
              let param = {
                workPersonCardNum:this.form.oldPersonCardNum,
                bodyTemperature:this.form.bodyTemperature,
                scene:JSON.parse(this.$route.query.item).orgType,
                signJobDicTypeCode:'job',
                signType:this.form.signType,
                signJobPidValSet:this.form.signActionPidValSet,
                sceneId:this.$route.params.id
              }
              this.getworkPersonSign(param).then((res) => {
                if (res.code == 200) {
                  this.$message.success('刷卡成功')
                  this.$parent.getList()
                  this.$parent.isSwipingCard = false
                }else if(res.code != 200 ||res.code != '200'){
                  this.$message.error(res.msg);

                }
              })
            }

          } else {

            console.log('error submit!!');
            return false;
          }
        });
      // if(this.form.oldPersonCardNum==''||this.form.oldPersonCardNum==''||this.form.servicePro.length==0){
      //   this.$message.error('请填写信息')
      // }

      // this.$message.success('刷卡成功')
      // this.$parent.isSwipingCard = false
    },
    handleCheckedCitiesChange(){}
  }
}
</script>

<style lang="scss" scoped>
.swipingCard{
  height:80%;
}
</style>>
