<template>
  <div class="wrapper">
    <el-form ref="form" :model="form" :rules="rules" label-width="130px" style="overflow-x:hidden;overflow-y:auto;">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" :disabled="true" style="width:100%;" placeholder="">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="出生日期" prop="birthDay">
            <el-date-picker
              v-model="form.birthDay"
              :disabled="true"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="身份证号" prop="identityCards">
            <el-input v-model="form.identityCards" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学历" prop="education">
            <el-select v-model="form.education" :disabled="true" style="width:100%;" placeholder="">
              <el-option
                v-for="item in educationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="contTel">
            <el-input v-model="form.contTel" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="职称">
            <el-input v-model="form.jobTitle" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从业年限">
            <el-input v-model="form.workYears" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从业资质">
            <el-input v-model="form.professionalQualification" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="卡号">
            <el-input v-model="form.cardNum" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="16">
          <el-form-item label="服务单位">
            <el-input v-model="form.serviceSiteName" placeholder="请选择服务单位" auto-complete="off" :disabled="true">
              <el-button slot="append" type="primary" icon="el-icon-search" />
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-form-item label="所属区域" prop="tenant">
            <region-form ref="regionForm" v-model="form.tenant" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" :disabled="true" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="身份证照片反面" prop="scannedContract">
            <upload-img
              :limit="1"
              :pic-disable="true"
              :upload-lists="scannedContractList"
              @uploadChange="uploadSuccess3"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="资格证书" prop="insurancePolicy">
            <upload-img
              :limit="1"
              :pic-disable="true"
              :upload-lists="insurancePolicyList"
              @uploadChange="uploadSuccess4"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="健康证" prop="healthReport">
            <upload-img
              :limit="1"
              :pic-disable="true"
              :upload-lists="healthReportList"
              @uploadChange="uploadSuccess1"
            />
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="核酸检测证明" prop="testCertificate">-->
<!--            <upload-img-->
<!--              :limit="1"-->
<!--              :pic-disable="true"-->
<!--              :upload-lists="testCertificateList"-->
<!--              @uploadChange="uploadSuccess2"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
    </el-form>
    <div class="m-footer">
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>

    <el-dialog title="选择服务单位" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <div>
          服务单位名称：
          <el-input
            v-model="searchData.orgName"
            style="width:200px;margin-right:10px;"
            size="medium"
            placeholder="请输入服务单位名称"
          />
          <el-button size="medium" type="primary" @click="search">搜索</el-button>
        </div>
        <div style="margin-top:10px;">
          <el-table ref="singleTable" :data="tableData" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  v-model="radio"
                  :label="scope.$index"
                  style="color: #fff;padding-left: 10px; margin-right: -25px;"
                  @change.native="getCurrentRow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="orgName" label="服务单位名称" width="200" />
            <el-table-column prop="address" label="服务单位地址" width="396" />
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              :current-page="tablePage.currentPage"
              :page-sizes="[5, 10, 15]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'

export default {
  name: 'BaseInfo',
  components: {
    UploadImg,
    RegionForm
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      },
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      visible: false,
      searchData: {
        orgName: ''
      },
      visible: false,
      titleName: '',
      form: {},
      healthReportList: [],
      testCertificateList: [],
      scannedContractList: [],
      insurancePolicyList: [],
      serveOrgList: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      communityList: [],
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      rules: {
      },
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      educationList: [
        {
          value: '文盲及半文盲',
          label: '文盲及半文盲'
        },
        {
          value: '小学',
          label: '小学'
        },
        {
          value: '初中',
          label: '初中'
        },
        {
          value: '中专',
          label: '中专'
        },
        {
          value: '高中',
          label: '高中'
        },
        {
          value: '大专',
          label: '大专'
        },
        {
          value: '本科',
          label: '本科'
        },
        {
          value: '硕士及以上',
          label: '硕士及以上'
        }
      ]
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
    this.getProvincesList()
  },
  methods: {
    ...mapActions([
      'downloadFile',
      'addWorkPersonServe',
      'updateWorkPersonServe',
      'getServeOrgsListServe',
      'detailWorkPersonServe',
      'getProvincesServe',
      'getCitysServe',
      'getAreasServe',
      'getStreetsServe',
      'getCommunitysServe',
      'getPersonOrgListServe'
    ]),
    initView() {
      this.getServiceComanyInfo()
      this.titleName = '修改从业人员'
      this.itemData = this.$route.query.item != undefined ? JSON.parse(this.$route.query.item) : ''
      const params = {
        id: this.itemData.id ? this.itemData.id : this.$route.params.id
      }
      let data = null
      const server = 'detailWorkPersonServe'
      this[server](params).then((res) => {
        if (res.code == 200) {
          data = res.data
          this.form = {
            name: data.name,
            tenant: data.tenant.split(',').slice(2),
            birthDay: data.birthDay,
            sex: data.sex,
            contTel: data.contTel,
            jobTitle: data.jobTitle,
            workYears: data.workYears,
            serviceSiteId: data.serviceSiteId,
            serviceSiteName: data.compName,
            id: this.itemData.id,
            identityCards: data.identityCards,
            education: data.education,
            affiliatedUnit: data.affiliatedUnit,
            professionalQualification: data.professionalQualification,
            provice: data.provice,
            city: data.city,
            area: data.area,
            address: data.address,
            healthReport: data.healthReport,
            testCertificate: data.testCertificate,
            scannedContract: data.scannedContract,
            insurancePolicy: data.insurancePolicy,
            cardNum: data.cardNum

          }

          this.$nextTick(() => {
            this.$refs.regionForm.initRegion()
          })
          if (data.healthReport) {
            this.getFileDetail1(data.healthReport)
          }
          if (data.testCertificate) {
            this.getFileDetail2(data.testCertificate)
          }
          if (data.scannedContract) {
            this.getFileDetail3(data.scannedContract)
          }
          if (data.insurancePolicy) {
            this.getFileDetail4(data.insurancePolicy)
          }
        }
      })
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.healthReportList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.testCertificateList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail3(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.scannedContractList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail4(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.insurancePolicyList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.healthReport = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.testCertificate = data.join(',')
    },
    uploadSuccess3(data) {
      this.form.scannedContract = data.join(',')
    },
    uploadSuccess4(data) {
      this.form.insurancePolicy = data.join(',')
    },
    getProvincesList() {
      this.getProvincesServe().then((res) => {
        if (res.code == 200) {
          this.provinceList = res.data
        }
      })
    },

    // 获取服务企业
    getServeOrgList() {
      const params = {
        current: 1,
        size: 99,
        param: {}
      }
      this.getServeOrgsListServe(params).then((res) => {
        if (res.code == 200) {
          this.serveOrgList = res.data.records
        }
      })
    },
    getCurrentRow(row) {
      this.form.serviceSiteId = row.orgId
      this.form.serviceSiteName = row.orgName
    },
    search() {
      this.getServiceComanyInfo()
    },
    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getServiceComanyInfo()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getServiceComanyInfo()
    },
    getServiceComanyInfo() {
      const params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          orgName: this.searchData.orgName
        }
      }
      const server = 'getPersonOrgListServe'
      this[server](params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    selectServiceCompany() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }

  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;

    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
