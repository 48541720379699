<template>
  <!-- 从业人员详情 -->
  <div class="wrapper">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <base-info></base-info>
      </el-tab-pane>
      <el-tab-pane label="服务记录" name="second">

        <service-record v-permission="'WorkPersonDetailSecondTab'" :serviceWorkPersonIdProp=serviceWorkPersonId :hasTenant="true" v-if="isrecord"></service-record>


      </el-tab-pane>
      <el-tab-pane label="服务对象" name="third">
        <service-object v-permission="'WorkPersonDetailThirdTab'" :hasTenant="true"></service-object>
      </el-tab-pane>
      <el-tab-pane label="活动记录" name="fourth">
        <activity-record v-permission="'WorkPersonDetailFourthTab'" v-if="isActiveRecord" :hasTenant="true"></activity-record>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>
<script>
import personSign from '../../nursingHome/mechaismManage/personSign/index.vue'
import baseInfo from './baseInfo.vue'
import serviceRecord from '../../HomeCare/ServeRecords/list.vue'
import serviceObject from '../../workPerson/serviceObject/index.vue'
import activityRecord from '../ServeOrgs/components/activityRecord.vue'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$route.params.tab != 1) {
        if (from.name == 'AddActivityRecord') {
          vm.activeName = 'fourth'
          vm.isActiveRecord = true
        }
      }
      // 通过 `vm` 访问组件实例
    })
  },
  name: 'Detail',
  components: { personSign, baseInfo, serviceRecord, serviceObject, activityRecord },
  data() {
    return {
      activeName: 'first',
      isrecord: false,
      signType: 2,  //签到类型 1老人签到 2 从业人员签到
      serviceWorkPersonId: this.$route.query.item != undefined ? ((JSON.parse(this.$route.query.item)).id) : '',
      isActiveRecord: false
    }
  },
  computed: {},
  created() {
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name == 'second') {

        this.isrecord = true
      } else if (tab.name == 'fourth') {
        this.isActiveRecord = true
      } else {
        this.isrecord = false
        this.isActiveRecord = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: auto;

  .m-content {
    .mc-item {
      line-height: 30px;

      span {
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
