<template>
  <div>
    <div class="header">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-button type="primary" v-if="!this.$route.query.oldPersonId && !this.$route.query.workPersonId"
              icon="el-icon-edit" size='medium' @click="addBtn('add')">添加</el-button>
          </el-col>
          <el-form-item>
            <el-col :span="21">
              <el-date-picker v-model="form.fromDateTime" type="date" placeholder="选择日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
              </el-date-picker>
              ~
              <el-date-picker v-model="form.toDateTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-button style="margin-left:10px" type="primary" size='medium' @click="search()">搜索</el-button>
            </el-col>
            <!-- <el-col :span="3">


              </el-col> -->
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="main">
      <el-table stripe border :data="tableData" show-overflow-tooltip :tooltip-effect="'tooltipStyle'">
        <el-table-column type="index" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="actStartDate" label="活动日期" align="center">
        </el-table-column>
        <el-table-column prop="actName" label="活动名称" align="center">
        </el-table-column>
        <el-table-column prop="organizer" label="主办单位" align="center">
        </el-table-column>
        <el-table-column prop="actAddress" label="活动地点" align="center">
        </el-table-column>
        <el-table-column prop="actContent" label="活动内容" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="actPeopleNum" label="服务人数" align="center">
        </el-table-column>
        <el-table-column prop="actTimeDuration" label="活动时长" align="center">
        </el-table-column>
        <el-table-column prop="signDurationValue" label="活动起止时间" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.actStartTime }}</p>
            <p style="color:red">{{ scope.row.actEndTime }}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="addBtn('detail', scope.row)" v-if="!ismore" type="text" size="small">详情</el-button>
            <vxe-button type="text" icon="el-icon-plus" size="mini" @click="ismore = true"
              v-if="(!ismore && !$route.query.oldPersonId) && (!ismore && !$route.query.workPersonId)"></vxe-button>
            <el-button @click="addBtn('edit', scope.row)" v-if="ismore" type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" v-if="ismore" @click="deleteRecord(scope.row)">删除</el-button>
            <vxe-button type="text" size="mini" icon="el-icon-minus" @click="ismore = false" v-if="ismore"></vxe-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: right; margin-top: 10px">
      <el-pagination background @size-change="handlePersonSizeChange" @current-change="handlePersonCurrentChange"
        :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        layout="total,jumper,prev, pager, next,sizes" :total="totalResult"></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    //是否分租户
    hasTenant: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: {
        fromDateTime: '',
        toDateTime: ''

      },
      tableData: [],
      totalResult: 0,
      currentPage: 1,
      pageSize: 10,
      align: 'left',
      pageSizes: [10, 20, 50, 100, 500, 1000],
      value1: '',
      ismore: false
    }
  },
  created() {
    this.getActList()
  },
  methods: {
    ...mapActions([
      "queryActRecoedList",//查询列表
      'deleteActRecord'//删除活动记录
    ]),
    search() {
      this.getActList()
    },
    addBtn(type, rowData) {
      this.$router.push({
        name: 'AddActivityRecord',
        params: {
          type: type,
          id: this.$route.params.id
        },
        query: {
          item: this.$route.query.item,
          rowData: rowData,
        }
      })

    },
    handlePersonCurrentChange() { },
    handlePersonSizeChange() { },
    /**
     * 查询活动列表
     */
    getActList() {
      console.log(this.$route.query);
      this.queryActRecoedList({
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        actCategory: this.$route.query.item ? JSON.parse(this.$route.query.item).entCmType : null,
        cmOrEntId: this.$route.query.cmOrEntId,
        fromDateTime: this.form.fromDateTime,
        toDateTime: this.form.toDateTime,
        oldPersonId: this.$route.query.oldPersonId,
        workPersonId: this.$route.query.workPersonId,
        hasTenant: this.hasTenant
      }).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.tableData = res.data.records
          this.totalResult = +res.data.total
        }
        //this.loading = false
      })
    },
    deleteRecord(row) {
      console.log(row);
      this.$XModal.confirm("您确定要删除该数据?").then(type => {
        if (type === "confirm") {
          let params = {
            id: row.id
          }
          this.deleteActRecord(params).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$XModal.message({
                message: '删除成功',
                status: "success"
              });
              this.getActList()
            }
          }).catch(err => {
            console.log(err);
          })
        }
      })

    }
  }
}
</script>



<style >
.el-tooltip__popper {
  max-width: 30% !important;
}

.is-tooltipStyle {
  font-size: 14px;
  color: #fff;
  background-color: black;
}
</style>
